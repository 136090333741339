import * as React from 'react';

import BasicLayout from '../layouts/BasicLayout';
import { TitleWrapper } from '../components/listPages/reusableComponents';
import LogoWithSubtitle from '../components/LogoWithSubtitle';
import PageMeta from '../components/PageMeta';
import blueRedPill from '../images/blue-pill-red-pill.jpg';
import styled from 'styled-components';
import theme from '../styles/theme';
import { Link } from 'gatsby';

const MainWrapper = styled.section`
  max-width: ${theme.containerWidth};
  margin: 4rem auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  img {
    flex: 1 0 280px;
    max-width: 450px;
    margin-right: 1rem;
  }
`;

export const NotFoundPage: React.SFC<{}> = props => {
  return (
    <BasicLayout>
      <PageMeta title="404 - Página não encontrada!" />
      <TitleWrapper>
        <LogoWithSubtitle subtitle="$#51!dh*&" />
        <h1>Ooops... Página não encontrada!</h1>
        <p style={{ marginTop: '-1rem' }}>
          Parece que você se perdeu... ou encontrou uma falha na Matrix{' '}
          <span role="img" aria-label="Emoji de olhos arregalados">
            👀
          </span>
        </p>
      </TitleWrapper>
      <MainWrapper>
        <img src={blueRedPill} alt="Foto da escolha das pílulas do Matrix" />
        <div>
          <p>
            Você pode escolher a pílula azul e sair do site sem conhecer o mundo
            da ciência de dados, ou ir com a <em>pílula vermelha</em> e{' '}
            <Link to="/blog">ler nosso blog</Link>,{' '}
            <a href="https://www.datahackers.news/">
              explorar nossa Newsletter
            </a>{' '}
            ou <Link to="/podcast">ouvir nosso podcast</Link>.
          </p>
          <p>
            A escolha é sua,{' '}
            <b>
              o mundo está em suas mãos{' '}
              <span role="img" aria-label="Emoji de um gato mascarado">
                🐱‍👤
              </span>
            </b>
          </p>
        </div>
      </MainWrapper>
    </BasicLayout>
  );
};

NotFoundPage.displayName = 'NotFoundPage';

export default NotFoundPage;
